<template>
  <div>
    <h4 class="bold mb-4">Mis pendientes</h4>
    <div class="d-flex align-center">
      <span class="material-symbols-rounded"> description </span>
      <span class="bold h5 ml-3">Simulaciones</span>
    </div>

    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 3"
        :key="index"
        class="rounded-lg mb-2 mt-4"
        type="card"
        max-height="170px">
      </v-skeleton-loader>
    </div>

    <div v-else-if="!Cotizaciones.length > 0" class="transparent">
      <v-img
        class="text-center mx-auto"
        max-width="250px"
        width="250"
        src="@/assets/sin_cotizaciones-01.svg" />
      <h3 class="global-pendentText text-center mt-6">
        No se encontró simulaciones
      </h3>
    </div>

    <div v-else>
      <div v-for="(item, i) in Cotizaciones" :key="i">
        <PendingCard :loading="item.loading" height="200">
          <template v-slot:number>
            <p class="title-color">
              Nº Simulación {{ item.numero_cotizacion }}
            </p>
          </template>
          <template v-slot:name>
            <p class="title-color">{{ item.cliente }}</p>
          </template>
          <template v-slot:description>
            <p class="title-color" v-if="item?.obj_marca">
              <b>
                {{
                  `${item.obj_marca?.marca_descripcion ?? ""} ${
                    item.obj_modelo?.modelo_descripcion ?? ""
                  }`
                }}
              </b>
            </p>
            <p v-else class="title-color">
              <b>
                {{ item?.categoria_descripcion ?? "" }}
              </b>
            </p>
          </template>
          <template v-slot:rut>
            <span class="title-color">
              {{ item.numero_documento | rutFormat }}
            </span>
          </template>
          <template v-slot:detail>
            <p class="title-color font-weight-bold">
              {{ item.estado_descripcion }}
            </p>
          </template>
          <template v-slot:button>
            <v-btn
              @click="onPress(item)"
              rounded
              block
              color="primary"
              class="text-none mt-2"
              :loading="item.loading">
              Terminar simulación
            </v-btn>
          </template>
        </PendingCard>
      </div>

      <p class="text-center mt-5">
        <v-btn
          color="primary"
          text
          @click="$router.push({ name: 'quotes' })"
          class="text-none">
          Ver más simulaciones <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </p>
    </div>
  </div>
</template>
<script>
import PendingCard from "@/components/Pages-Components/Dashboard/SideBarCards.vue";
import { mapGetters } from "vuex";

export default {
  name: "PendingQuotes",
  props: {
    AccionButton: { type: Function },
    loading: {
      type: Boolean,
      default: false,
      description: "Cargando cotizaciones.",
    },
  },
  components: {
    PendingCard,
  },
  methods: {
    onPress(item) {
      item.loading = true;
      this.$emit("action-button", item);
    },
  },
  computed: {
    ...mapGetters("Quot", ["Cotizaciones"]),
  },
};
</script>
