<!-- eslint-disable vue/no-async-in-computed-properties -->
<template>
  <v-col cols="12" md="10" offset-md="1">
    <div>
      <v-card elevation="0">
        <v-card-text class="container-form">
          <div v-if="isLoading">
            <div>
              <v-skeleton-loader
                type="text"
                width="320"
                height="40"></v-skeleton-loader>
              <div class="d-flex align-center mb-6">
                <div class="d-flex align-center">
                  <v-skeleton-loader
                    type="avatar"
                    class="custom-avatar"></v-skeleton-loader>
                  <v-skeleton-loader
                    type="text"
                    width="100"
                    class="mt-1 ml-2" />
                </div>
                <div class="d-flex align-center ml-4">
                  <v-skeleton-loader
                    type="avatar"
                    class="custom-avatar"></v-skeleton-loader>
                  <v-skeleton-loader
                    type="text"
                    width="100"
                    class="mt-1 ml-2" />
                </div>
              </div>
              <v-skeleton-loader type="text" width="210"></v-skeleton-loader>
              <v-skeleton-loader type="text" class="custom" height="60" />
              <v-skeleton-loader
                type="button"
                width="100%"
                class="custom-btn" />
            </div>
          </div>
          <v-form ref="form" v-else>
            <div class="d-flex flex-column justify-center">
              <h4 class="py-3">¿Con cuál documento deseas cotizar?</h4>
              <template v-if="country === countries.PE">
                <v-radio-group
                  v-model="documento"
                  row
                  :rules="[rules.document]">
                  <v-radio
                    v-for="(item, index) in tipoDocumentos"
                    :label="item.descripcion"
                    :value="item.codigo"
                    :key="index"></v-radio>
                </v-radio-group>
              </template>
              <template v-else>
                <v-radio-group
                  v-model="documento"
                  row
                  :rules="[rules.document]">
                  <v-radio
                    v-for="(item, index) in tipoPersonas"
                    :label="item.descripcion"
                    :value="item.codigo"
                    :key="index"></v-radio>
                </v-radio-group>
              </template>

              <div>
                <h4 class="py-4">Ingresa el N° documento</h4>
                <v-text-field
                  :placeholder="placeholder"
                  prepend-inner-icon="mdi-magnify"
                  v-model="rut"
                  :rules="[rules.required, rules.rut, rules.isValid]"
                  persistent-placeholder
                  outlined
                  dense
                  v-mask="rutMask"
                  name="buscar_cli"
                  class="rounded-lg">
                </v-text-field>
              </div>
              <div>
                <v-btn
                  block
                  rounded
                  class="text-none"
                  color="primary"
                  @click="handlerSearchUser()"
                  :loading="loading"
                  >Buscar</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>

      <!-- MODAL NUEVO CLIENTE     -->
      <v-dialog v-model="dialog" width="517px" attach="#main-container">
        <v-card class="py-4">
          <v-card-text>
            <div class="d-flex justify-space-between align-center">
              <span class="h5 semiBold">Registro de nuevo cliente</span>
              <v-btn icon color="error" @click="handlerCloseDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="d-flex align-center mt-6 justify-center">
              <v-icon x-large>mdi-account-search-outline</v-icon>
              <span class="p ml-6" v-if="country === 'CL'"
                >El <span class="semiBold">{{ "n° documento " + rut }}</span> no
                se encuentra registrado en el sistema
              </span>
              <span class="p ml-6" v-if="country === 'PE'"
                >El <span class="semiBold">{{ "DNI " + rut }}</span> no se
                encuentra registrado en el sistema
              </span>
            </div>
            <div class="d-flex flex-column justify-center align-center my-6">
              <v-btn
                color="primary"
                @click="handlerCloseDialog()"
                width="214"
                height="41"
                class="text-none bg-white"
                outlined
                rounded
                >Volver a buscar</v-btn
              >
              <v-btn
                color="primary"
                class="text-none mt-4"
                width="214"
                height="41"
                rounded
                @click="addUser()"
                >Registrar cliente</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>
<script>
import { validarRutFormat, validarRut } from "@/store/resources/validate.js";
import { rutMask } from "@/helpers/mask.js";
import { COUNTRIES } from "@/helpers/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      valid: false,
      countries: COUNTRIES,
      tipos_documentos: JSON.parse(sessionStorage.getItem("tipo_documentos")),
      tipos_personas: null,
      rut: "",
      documento: null,
      placeholder: "Ej: 15686578-8",
      label: "Buscar cliente",
      loading: false,
      dialog: false,
      rules: {
        required: (value) =>
          !!value || "Escriba el RUT para comenzar a buscar...",
        document: (value) => !!value || "Debe seleccionar tipo de documento.",
        rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
        isValid: (value) =>
          validarRut(value) || "No se ha logrado reconocer el RUT",
      },
    };
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo"]),
    ...mapGetters("Internationalization", [
      "tipoDocumentos",
      "tipoPersonas",
      "isLoading",
    ]),
    rutMask: () => rutMask,

    country() {
      return this.$store.getters.getCountry;
    },
  },

  async mounted() {
    if (!this.tipo_documentos) {
      this.tipos_documentos = this.getTiposDocumentos(this.country);
    }
  },

  methods: {
    ...mapActions("Users", ["findUser"]),
    ...mapActions("Internationalization", [
      "getTiposDocumentos",
      "getTiposPersonas",
    ]),

    handlerSearchUser() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        this.loading = true;
        this.findUser({
          rut: this.rut.replace(/[^A-Z0-9]/gi, ""),
          tipo_documento:
            this.country === this.countries.CL ? "RUT" : this.documento,
        })
          .then((response) => {
            sessionStorage.setItem("client", JSON.stringify(response));
            this.$router.push({
              name: "UserDetail",
              params: {
                rut: response.numero_documento,
                tipo_documento:
                  this.country === this.countries.CL ? "RUT" : this.documento,
              },
            });
          })
          .catch(() => {
            this.dialog = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handlerCloseDialog() {
      this.dialog = false;
    },

    addUser() {
      this.$router.push({
        name: "NewEditUser",
        params: {
          rut: this.rut.replace(/[^A-Z0-9]/gi, ""),
          document: this.documento,
          isEditUser: true,
        },
      });
    },
  },
  watch: {
    country(val) {
      if (val) {
        this.documento = val === this.countries.CL ? "NAT" : null;
      }
    },
  },
};
</script>

<style>
.container-form {
  padding: 48px 90px;
}
.custom div.v-skeleton-loader__text {
  height: 35px;
}
.custom-btn div.v-skeleton-loader__button {
  width: 100%;
}
.custom-avatar .v-skeleton-loader__avatar {
  width: 24px !important;
  height: 24px;
}
</style>
